import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withRouter } from 'react-router';
import { Route, Switch, Router } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './css/App.css';  
 
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'; 
const history = createBrowserHistory();


// Si estamos en el entorno de producción, activamos google analytics universal para que detecte los cambios de vistas
const isGAEnabled = process.env.REACT_APP_ENV.includes('prod');
if (isGAEnabled) { 
  if (navigator.userAgent.indexOf('Speed Insights') == -1) {
    // process.env.REACT_APP_GANALYTICSID 
    ReactGA.initialize("UA-96113640-1");
    ReactGA.ga('send', 'pageview', window.location.pathname + window.location.search);
    var i=0;
    history.listen(location => {
      i++; 
      if(i==1) {
        ReactGA.event({
          category: 'Usuarios únicos que cambiaron al menos 1 página',
          action: 'Cambió de página'
        });
      }
      ReactGA.ga('send', 'pageview', window.location.pathname + window.location.search);
    });  
  }
}



const url = window.location.href;

let lang;
if (url.search('/en') !== -1) {
  lang = 'en';
} else {
  lang = 'es';
}
const listaPalabras = JSON.stringify(require('./translate/' + lang + '.json'));
const wordList = JSON.parse(listaPalabras);

const AsyncPageDefault = Loadable({
  loader: () => import(/* webpackChunkName: "pageHome" */ './views/home/Home'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: ['pageHome'],
});

const AsyncPageEvent = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/event/Event'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.event],
});

const AsyncPageProduct = Loadable({
  loader: () => import(/* webpackChunkName: "pageHome" */ './views/product/Product'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.product],
});

const AsyncPageProfileMyProduct = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/My-product'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileMyProduct],
});
const AsyncPageProfile = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Profile'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profile],
});
const AsyncPageProfilePassword = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Password'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profilePassword],
});
const AsyncPageProfileInsurance = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/My-insurance'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileInsurance],
});
const AsyncPageProfileProductDetails = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Product-details'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileProductDetails],
});

const AsyncPageProfileQuestions = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Questions'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileQuestions],
});
const AsyncPageProfileHelp = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Help'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileHelp],
});
const AsyncPageProfileNotify = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/profile/Notify'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.profileNotify],
});

const AsyncPageRecommender = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/recommender/Recommender-1'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommender1],
});

const AsyncPageRecommender2 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/recommender/Recommender-2'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommender2],
});

const AsyncPageRecommender3 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/recommender/Recommender-3'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommender3],
});

const AsyncPageRecommender4 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/recommender/Recommender-4'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommender4],
});

const AsyncPageRecommender5 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/recommender/Recommender-5'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommender5],
});

const AsyncPageRecommenderIntro = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-intro'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderIntro],
});

const AsyncPageRecommenderMedical = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-1'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical],
});

const AsyncPageRecommenderMedical2 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-2'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical2],
});

const AsyncPageRecommenderMedical3 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-3'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical3],
});

const AsyncPageRecommenderMedical4 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-4'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical4],
});

const AsyncPageRecommenderMedical5 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-5'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical5],
});

const AsyncPageRecommenderMedical6 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/medical-data/Recommender-medical-data-6'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderMedical6],
});

const AsyncPageRecommenderContratationIntro = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-intro'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratationIntro],
});

const AsyncPageRecommenderContratation1 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-1'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratation1],
});

const AsyncPageRecommenderContratation2 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-2'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratation2],
});
const AsyncPageRecommenderContratation3 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-3'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratation3],
});
const AsyncPageRecommenderContratation4 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-4'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratation4],
});
const AsyncPageRecommenderContratation5 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-5'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.recommenderContratation5],
});

const AsyncPageAccess = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/access/Access'),
  loading: () => <div style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.access],
});
const AsyncPageHowWork = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/how-work/How-work'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.howWork],
});
const AsyncPageAbout = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/about/About'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.about],
});
const AsyncPageFaqs = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/faqs/Faqs'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.faqs],
});
const AsyncPageCookies = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/standard/Cookies'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.cookies],
});
const AsyncPagePrivacy = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/standard/Privacy'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.privacy],
});
const AsyncPageTerms = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/standard/Terms'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.terms],
});
const AsyncPageContact = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/standard/Contact'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.contact],
});
const AsyncPageEmailOk = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/email-verify/email-ok'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.emailOk],
});
const AsyncPageEmailMarketing = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/email-verify/email-marketing'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.emailMarketing],
});
const AsyncPageAttemptsExceeded = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/attempts-exceeded/attempts-exceeded'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.emailMarketing],
});
const AsyncPage404 = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/page-404/Page-404'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.page404],
});
const AsyncPageMedlive = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/standard/Medlive'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.medlive],
});
const AsyncNotInsurable = Loadable({
  loader: () => import(/* webpackChunkName: "pageAnother" */ './views/contratation/Contratation-ko-50'),
  loading: () => <div id="spinner" style={{textAlign: 'center'}}><Spinner animation="border"/></div>,
  modules: [wordList.url.notInsurable],
});


 
// Eliminar errores consola
console.warn = () => {};
console.error = () => {};
console.disableYellowBox = true;
class App extends Component {
  constructor (props) {
    super(props);

    if (lang === 'es') {
      lang = '';
    } else {
      lang = lang + '/';
    }
    this.state = {
      lang: lang,
      wordListUrl: wordList.url,
      wordList: wordList.app,
      modalContact: false,
      show: false,
      setShow: false
    }; 
  }
 

  render () { 
    const {wordListUrl } = this.state; 
     
    return (
      <div>  
  

          <Router history={history}>
          <Switch>
            <Route exact path="/" component={AsyncPageDefault}/>
            <Route exact path={"/" + wordListUrl.event} component={AsyncPageEvent}/>
            <Route exact path={'/' + wordListUrl.recommender1} component={AsyncPageRecommender}/>
            <Route exact path={'/' + wordListUrl.recommender2} component={AsyncPageRecommender2}/>
            <Route exact path={'/' + wordListUrl.recommender3} component={AsyncPageRecommender3}/>
            <Route exact path={'/' + wordListUrl.recommender4} component={AsyncPageRecommender4}/>
            <Route exact path={'/' + wordListUrl.recommender5} component={AsyncPageRecommender5}/>
            <Route exact path={'/' + wordListUrl.recommenderIntro} component={AsyncPageRecommenderIntro}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical} component={AsyncPageRecommenderMedical}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical2} component={AsyncPageRecommenderMedical2}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical3} component={AsyncPageRecommenderMedical3}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical4} component={AsyncPageRecommenderMedical4}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical5} component={AsyncPageRecommenderMedical5}/>
            <Route exact path={'/' + wordListUrl.recommenderMedical6} component={AsyncPageRecommenderMedical6}/>
            <Route exact path={'/' + wordListUrl.recommenderContratationIntro}
                   component={AsyncPageRecommenderContratationIntro}/>
            <Route exact path={'/' + wordListUrl.recommenderContratation1}
                   component={AsyncPageRecommenderContratation1}/>
            <Route exact path={'/' + wordListUrl.recommenderContratation2}
                   component={AsyncPageRecommenderContratation2}/>
            <Route exact path={'/' + wordListUrl.recommenderContratation3}
                   component={AsyncPageRecommenderContratation3}/>
            <Route exact path={'/' + wordListUrl.recommenderContratation4}
                   component={AsyncPageRecommenderContratation4}/>
            <Route exact path={'/' + wordListUrl.recommenderContratation5}
                   component={AsyncPageRecommenderContratation5}/>
            <Route exact path={'/' + wordListUrl.access} component={AsyncPageAccess}/>
            <Route exact path={'/' + wordListUrl.howWork} component={AsyncPageHowWork}/>
            <Route exact path={'/' + wordListUrl.about} component={AsyncPageAbout}/>
            <Route exact path={'/' + wordListUrl.product} component={AsyncPageProduct}/>
            <Route exact path={'/' + wordListUrl.faqs} component={AsyncPageFaqs}/>
            <Route exact path={'/' + wordListUrl.cookies} component={AsyncPageCookies}/>
            <Route exact path={'/' + wordListUrl.privacy} component={AsyncPagePrivacy}/>
            <Route exact path={'/' + wordListUrl.terms} component={AsyncPageTerms}/>
            <Route exact path={'/' + wordListUrl.contact} component={AsyncPageContact}/>
            <Route exact path={'/' + wordListUrl.profileMyProduct} component={AsyncPageProfileMyProduct}/>
            <Route exact path={'/' + wordListUrl.profileQuestions} component={AsyncPageProfileQuestions}/>
            <Route exact path={'/' + wordListUrl.profileHelp} component={AsyncPageProfileHelp}/>
            <Route exact path={'/' + wordListUrl.profileNotify} component={AsyncPageProfileNotify}/>
            <Route exact path={'/' + wordListUrl.profile} component={AsyncPageProfile}/>
            <Route exact path={'/' + wordListUrl.profilePassword} component={AsyncPageProfilePassword}/>
            <Route exact path={'/' + wordListUrl.profileInsurance} component={AsyncPageProfileInsurance}/>
            <Route exact path={'/' + wordListUrl.profileProductDetails} component={AsyncPageProfileProductDetails}/>
            <Route exact path={'/' + wordListUrl.medlive} component={AsyncPageMedlive}/>
            <Route exact path={'/' + wordListUrl.attemptsExceeded} component={AsyncPageAttemptsExceeded}/>
            <Route exact path={'/api/' + wordListUrl.emailOk} component={AsyncPageEmailOk}/>
            <Route exact path={'/api/' + wordListUrl.emailMarketing} component={AsyncPageEmailMarketing}/>
            <Route exact path={'/' + wordListUrl.notInsurable} component={AsyncNotInsurable}/>
            <Route component={AsyncPage404}/>
          </Switch> 
          </Router>
      </div>
    );
  }
}

export default withRouter((App));
