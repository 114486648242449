import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const AppBundle = (
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);
//
window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(
      AppBundle,
      document.getElementById('root')
    );
  });
}; 
